import React from 'react';
import { Dialog } from 'wix-ui-tpa/Dialog';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { useExperiments, useFedopsLogger, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../common/components/runtime-context';
import { getCancelConfirmModalSubscription, isCancelConfirmModalOpen } from '../selectors';
import { classes } from './CancelConfirmModal.st.css';
import { RootState } from '../state';
import { EXPERIMENT_USE_BASS_API } from '../constants';
import { Interactions } from '../../../types/interactions';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

export const CancelConfirmModal = ({ isOpen, subscription, onClose, onCancel, onConfirm }: RuntimeProps) => {
  const fedops = useFedopsLogger();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isRecurring = experiments.enabled(EXPERIMENT_USE_BASS_API)
    ? subscription?.billingSettings?.totalCycles > 1
    : subscription?.recurring;
  return (
    <Dialog
      data-hook="cancel-confirm-modal"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonAriaLabel={t('app.cancel-confirm-modal.close')}
    >
      {isOpen ? (
        <>
          <div className={classes.title}>
            <Text className={classes.text} typography={TYPOGRAPHY.largeTitle}>
              {t('app.cancel-confirm-modal.title', { subscriptionName: subscription.name })}
            </Text>
          </div>
          <div data-hook="cancel-confirm-modal-description" className={classes.description}>
            <Text className={classes.text} typography={TYPOGRAPHY.runningText}>
              {t(
                isRecurring ? 'app.cancel-confirm-modal.description-recurring' : 'app.cancel-confirm-modal.description',
              )}
            </Text>
          </div>
          <div className={classes.actionContainer}>
            <Button className={classes.buttonPrimary} upgrade priority={PRIORITY.basicSecondary} onClick={onCancel}>
              {t('app.cancel-confirm-modal.cancel')}
            </Button>
            <Button
              className={classes.buttonSecondary}
              upgrade
              priority={PRIORITY.basic}
              onClick={() => {
                fedops.interactionStarted(Interactions.SubscriptionCancel);
                onConfirm();
              }}
            >
              {t('app.cancel-confirm-modal.confirm')}
            </Button>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, { closeCancelConfirmModal, confirmCancel }: any) => ({
  isOpen: isCancelConfirmModalOpen(state),
  subscription: getCancelConfirmModalSubscription(state),
  onClose: () => closeCancelConfirmModal(),
  onCancel: () => closeCancelConfirmModal(),
  onConfirm: () => confirmCancel(),
});

export default connect(mapRuntimeToProps)(CancelConfirmModal);
